import buttonEnableDisable from "./helpers/recaptcha/buttonEnableDisable";
import initialiseForm from "./helpers/initializeForm";
import "jquery-mask-plugin";
import "./helpers/niceSelect";

var errorClass = "hasError";
var scrollPosition = 0,
    scrollSpeed = 1000;

const requestRepMain = () => {
    $("select").niceSelect();
    $(".specialty select").change(function() {
        $(".specialty .current").css("color", "#000000");
    });
    $('.anwser:not(".specialty") select').change(function() {
        $('.anwser:not(".specialty") .current').css("color", "#000000");
    });
    $("body").on("dynamicformcontentreplaced", function() {
        $(".text-component.aos-item.aos-init").hide();
        $("html, body").animate({ scrollTop: scrollPosition }, scrollSpeed);
    });

    $(document).ready(function() {
        $(".zip-code input").attr("type", "tel");

        initialiseForm();

        let elem = $(".FormControllerClass");
        elem.on("focusout", "input", buttonEnableDisable);
        elem.on("change", "input", buttonEnableDisable);
        elem.on("change", "select", buttonEnableDisable);
        $("#phone").attr("placeholder", "(   )");
        $("#phone").attr("type", "tel");
        $("#phone").mask("(999) 999 9999", { autoclear: false, placeholder: "(   )         " });
        $("#zip input").attr("type", "tel");
        $("#verify_email_address").focusout(function() {
            $(".confirm-email-msg1, .confirm-email-msg2").hide();
            var b = $("#verify_email_address")
                .val()
                .trim();
            var a = $("#email")
                .val()
                .trim();
            if (!$(this).hasClass("hasError") && a != b && a != "") {
                $(".confirm-email-msg2").show();
                $(".confirm-email-msg1").hide();
            } else {
                if (!$(this).hasClass("hasError") && a == b) {
                    $(".confirm-email-msg1").show();
                    $(".confirm-email-msg2").hide();
                }
            }
            if ($(this).hasClass("hasError") || $("#email").hasClass("hasError")) {
                $(".confirm-email-msg2").hide();
                $(".confirm-email-msg1").hide();
            }
        });
        $("#email").focusout(function() {
            var b = $("#verify_email_address")
                .val()
                .trim();
            var a = $("#email")
                .val()
                .trim();
            if (!$(this).hasClass("hasError") && a != b && !$("#verify_email_address").hasClass("hasError") && b != "") {
                $(".confirm-email-msg2").show();
                $(".confirm-email-msg1").hide();
            }
            if (!$(this).hasClass("hasError") && a == b && !$("#verify_email_address").hasClass("hasError")) {
                $(".confirm-email-msg1").show();
                $(".confirm-email-msg2").hide();
            }
            if (!$(this).hasClass("hasError") && $(".confirm-email-msg2").is(":visible") && a == b) {
                $(".confirm-email-msg1").show();
                $(".confirm-email-msg2").hide();
                $("#verify_email_address").removeClass("hasError");
            }
            if ($(this).hasClass("hasError")) {
                $(".confirm-email-msg2").hide();
                $(".confirm-email-msg1").hide();
            }
        });
        buttonEnableDisable();
    });

    var errorlbl = "#errorLb",
        validatelbl = "#validateLb";
    var isZipValid = false; //eslint-disable-line
    $(".zip-code input").on("keypress keyup blur", function(a) {
        $(".zip-code input").attr("type", "tel");
        $(this).val(
            $(this)
                .val()
                .replace(/[^\d].+/, "")
        );
        if (a.which < 48 || a.which > 57) {
            a.preventDefault();
        }
    });
    $("#zip").focusout(function() {
        let zipValue = $("#zip")
            .val()
            .trim();
        let dataIndex = $(this).attr("data-index");
        if (zipValue.length > 0) {
            $("#zip").removeClass(errorClass);
            $(errorlbl + dataIndex).attr("hidden", true);
            $(validatelbl + dataIndex).attr("hidden", true);
            isZipValid = true;
        } else {
            isZipValid = false;
            $(validatelbl + dataIndex).attr("hidden", true);
            $(errorlbl + dataIndex).removeAttr("hidden");
            $(errorlbl + dataIndex).removeClass("anwser label");
            $(errorlbl + dataIndex).addClass("anwser-mandatory-label");
            $("#zip").addClass(errorClass);
        }
    });

    //handling yes and no buttons
    $(".rq-rep-btn-yes").click(function() {
        $("#mainContent").removeClass("hidden");
        $("#answerNo").addClass("hidden");
    });
    $(".rq-rep-btn-no").click(function() {
        $("#mainContent").addClass("hidden");
        $("#answerNo").removeClass("hidden");
    });
};

export default requestRepMain;
