import createCookie from "../utils/createCookie";
import readCookie from "../utils/readCookie";
import getParameterByName from "../utils/getParameterByName";
import { indications } from "../utils/indications";

function init() {
    if (document.body.classList.contains("franchiseHomePage")) {
        //checks for homepage
        createCookie("indication", "franchise");
    } else if (getParameterByName("indication")) {
        //checks for parameter
        createCookie("indication", getParameterByName("indication"));
    } else {
        indications.forEach(indication => {
            if (window.location.pathname.includes(indication)) {
                createCookie("indication", indication);
            }
        });
        if (!readCookie("indication")) {
            createCookie("indication", "franchise");
        }
    }
    //Get MOC parameter
    if (getParameterByName("MOC")) createCookie("Dupixent-MOC", getParameterByName("MOC"));
    //Add indication to body
    document.body.classList.add("indication-" + readCookie("indication"));
}
export default { init };
