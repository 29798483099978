import chevronNav from "./chevronNav";
var $window = $(window),
    $body = $("body"),
    scrollTop,
    scrollDirection,
    startPosition = $window.scrollTop(),
    prevScrollDirection = null,
    prevScrollPos = 99999,
    threshold = 500,
    distScrolled;

function init() {
    if (document.querySelector(".mgnlAdmin")) return;
    $window.on("resize scroll orientationchange", function(e) {
        if (!$(".explore-more").length) {
            $body.hasClass("hcpFranchiseHome") ? desktopStickyHeader(e) : mobileStickyHeader(e);
        }
    });

    if ($(".explore-more").length > 0) {
        var stickyNavTopExplore = $(".explore-more").offset().top;

        var stickyNav = function() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > stickyNavTopExplore) {
                $(".explore-more").addClass("sticky");
            } else {
                $(".explore-more").removeClass("sticky");
            }
        };

        $window.on("resize scroll orientationchange", function() {
            stickyNav();
        });

        stickyNav();
    }

    var $root = $("html, body");
    $(".btn-anchor").on("click", function() {
        if (
            $($.attr(this, "href"))
                .closest(".aos-item")
                .hasClass("aos-animate")
        ) {
            var aosOffset = 0;
        } else {
            aosOffset = 100;
        }
        if ($(window).width() > 991) {
            var anchorNavHeight = $(".explore-more").outerHeight();
            $root.animate(
                {
                    scrollTop: $($.attr(this, "href")).offset().top - (anchorNavHeight + aosOffset)
                },
                500
            );
        } else {
            var anchorNavMobHeight = $(".explore-mobile").outerHeight();
            $root.animate(
                {
                    scrollTop: $($.attr(this, "href")).offset().top - (anchorNavMobHeight + aosOffset)
                },
                500
            );

            setTimeout(function() {
                $("body").addClass("header-hide");
            }, 750);
        }

        return false;
    });

    var hasStickyNavTop = $(".section-anchor-nav").length > 0;

    if (hasStickyNavTop) {
        // grab the initial top offset of the navigation
        var stickyNavTop = $(".section-anchor-nav").offset().top;

        // our function that decides weather the navigation bar should have "fixed" css position or not.
        var stickyAnchorNav = function() {
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top

            // if we've scrolled more than the navigation, change its position to fixed to stick to top,
            // otherwise change it back to relative
            if (scrollTop > stickyNavTop) {
                $(".section-anchor-nav").addClass("sticky");
            } else {
                $(".section-anchor-nav").removeClass("sticky");
            }
        };

        stickyAnchorNav();
        // and run it again every time you scroll
        $(window).on("scroll", function() {
            stickyAnchorNav();
        });
    }
    chevronNav.init();
}

function desktopStickyHeader() {
    //var distSticky = 111,
    var distSticky = $(".pageWrapper").offset().top - 60,
        scrollTop = $window.scrollTop();

    $body.removeClass("header-hide");

    //conditional to hide or show the header
    if (scrollTop < distSticky) {
        $body.removeClass("header-sticky");
    } else {
        $body.addClass("header-sticky");
    }
}

function mobileStickyHeader() {
    //var distSticky = parseInt($('header').css('border-top-width')) + (($('.eyebrow:visible').length) ? $('.eyebrow').outerHeight() : 0),
    var distSticky = $(".covid-wrapper").outerHeight();
    (scrollTop = $window.scrollTop()), (scrollDirection = prevScrollPos < scrollTop ? "down" : "up"), (distScrolled = Math.abs(startPosition - scrollTop));

    prevScrollPos = scrollTop;

    //determines if scroll direction changed since last event fire
    if (scrollDirection !== prevScrollDirection) {
        prevScrollDirection = scrollDirection;
        startPosition = $window.scrollTop();
        distScrolled = 0;
    }

    //conditional to hide or show the header
    if (scrollTop < distSticky + $("header").outerHeight()) {
        //above the anchor point
        $body.removeClass("header-hide header-sticky");
    } else if (scrollDirection == "down") {
        //DOWN
        $body.addClass("header-hide");
    } else if ($("header").outerHeight() && distScrolled > threshold) {
        //UP
        $body.removeClass("header-hide").addClass("header-sticky");
    }
}

// add scroll offset to fragment target (if there is one)
$(function() {
    setTimeout(delayedFragmentTargetOffset, 500);
});

function delayedFragmentTargetOffset() {
    if ($(window).width() > 991) {
        var offset = $(":target").offset(),
            offsetHeight = 80;
        if ($(".section-anchor-nav").is(":visible")) {
            offsetHeight = 160;
        }
        if (offset) {
            var scrollto = offset.top - offsetHeight; // minus fixed header height
            $("html, body").animate({ scrollTop: scrollto }, 0);
        }
    }
}

export default { init };
